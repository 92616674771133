<template>
  <div
    class="fixed inset-0 z-[200] flex items-center justify-center bg-[#000]/95"
  >
    <ClientOnly>
      <SvgIcon
        name="close"
        class="icons-medium absolute right-3 top-3 z-[201] cursor-pointer text-white-100 lg:hidden"
        @click="() => closeGallery()"
      />
      <div
        class="absolute bottom-[80px] left-0 top-0 z-[201] hidden w-1/2 cursor-pointer lg:block"
        @click="handleChangePrevSlide()"
      ></div>
      <div
        class="absolute bottom-[80px] right-0 top-0 z-[201] hidden w-1/2 cursor-pointer lg:block"
        @click="handleChangeNextSlide()"
      ></div>
      <div
        class="control absolute inset-x-0 bottom-0 z-[201] hidden h-[80px] items-center justify-end bg-[#000] text-white-100 opacity-0 transition-all duration-500 ease-in-out hover:opacity-100 lg:flex"
      >
        <div class="mx-auto">{{ currentSlide }} / {{ totalSlide }}</div>
        <div class="mr-4 grid grid-flow-col gap-6">
          <div class="grid grid-flow-col gap-2">
            <div
              v-if="!isPlayed"
              class="grid size-12 cursor-pointer place-content-center rounded-md transition-all duration-300 ease-in-out hover:bg-white-100/20"
              :class="{ 'pointer-events-none opacity-50': isLockAutoplay }"
              title="Автовоспроизведение"
              @click="setStartAutoplay()"
            >
              <SvgIcon name="play-icon" class="size-5" />
            </div>
            <div
              v-else
              class="grid size-12 cursor-pointer place-content-center rounded-md transition-all duration-300 ease-in-out hover:bg-white-100/20"
              title="Остановить автовоспроизведение"
              @click="setStopAutoplay()"
            >
              <SvgIcon name="stop-icon" class="size-5" />
            </div>

            <div
              class="relative w-[84px] select-none rounded-md transition-all duration-300 ease-in-out hover:bg-white-100/20"
              @click="toggleAutoplayTiming"
            >
              <div
                ref="dropdownRef"
                class="absolute bottom-full left-0 grid w-[84px] grid-flow-row rounded-md bg-white-100"
              >
                <div
                  v-for="timingButton in timingVariants"
                  v-show="selectedAutoplayTiming"
                  :key="timingButton.time"
                  class="grid cursor-pointer place-content-center border-b border-[#dedede] p-4 text-[16px] leading-[22px] text-black-100 transition-all duration-100 ease-in-out last:border-0 hover:bg-black-100/10"
                  :class="[
                    timingButton.time === autoplaySettings.delay
                      ? 'bg-black-100/30'
                      : timingButton.time === autoplayDelay
                      ? 'bg-black-100/30'
                      : '',
                  ]"
                  @click="setAutoplayTimeOut(timingButton.time)"
                >
                  {{ timingButton.text }}
                </div>
              </div>
              <div class="grid h-full place-content-center">
                {{ activeAutoplay }}
              </div>
            </div>
          </div>
          <div
            class="grid size-12 cursor-pointer place-content-center rounded-md text-white-100 transition-all duration-300 ease-in-out hover:bg-white-100/20"
            :class="{ 'bg-white-100/20': isFullScreen }"
            title="На весь экран"
            @click="toggleFullScreen"
          >
            <SvgIcon v-if="isFullScreen" name="fullscreen" class="size-8" />
            <SvgIcon v-else name="fullscreen-exit" class="size-8" />
          </div>
          <div
            class="grid size-12 cursor-pointer place-content-center rounded-md text-white-100 transition-all duration-300 ease-in-out hover:bg-white-100/20"
            title="Закрыть"
            @click="closeGallery()"
          >
            <SvgIcon name="close" class="size-8" />
          </div>
        </div>
      </div>
      <Swiper
        :modules="[Autoplay]"
        :slides-per-view="1"
        :autoplay="autoplaySettings"
        class="flex max-h-screen"
        @slide-change="changeSlide($event)"
        @reach-end="setFinalyAutoplay()"
        @swiper="onSwiper"
      >
        <SwiperSlide v-for="card in propsModal.slides" :key="card.id">
          <img
            v-lazy-load
            :src="card.src"
            alt=""
            class="mx-auto flex max-h-screen"
          />
        </SwiperSlide>
      </Swiper>
    </ClientOnly>
  </div>
</template>

<script setup>
import 'swiper/css';
import 'swiper/css/autoplay';

import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';

const timingVariants = [
  { time: 3000, text: '3 сек' },
  { time: 5000, text: '5 сек' },
  { time: 10000, text: '10 сек' },
  { time: 30000, text: '30 сек' },
  { time: 60000, text: '1 мин' },
  { time: 120000, text: '2 мин' },
  { time: 300000, text: '5 мин' },
];

const modal = useModalsStore();
const { onSwiper, slidePrev, slideNext, isEnd, startAutoplay, stopAutoplay } =
  useSwiper();

const propsModal = computed(() => modal.getPropsModal || {});

const isPlayed = ref(false);
const currentSlide = ref(1);
const isLockAutoplay = ref(false);
const isFullScreen = ref(propsModal.value?.fullScreen);
const selectedAutoplayTiming = ref(false);
const repeatClickEnd = ref(false);
const autoplayDelay = ref(timingVariants[0].time);
const dropdownRef = ref(null);

const totalSlide = computed(() => propsModal.value?.slides?.length);

const autoplaySettings = computed(() =>
  !isPlayed.value
    ? false
    : {
        delay: autoplayDelay.value,
        disableOnInteraction: false,
        stopOnLastSlide: true,
      },
);

const activeAutoplay = computed(() => {
  return timingVariants.find((item) => item.time === autoplayDelay.value)?.text;
});

const setStartAutoplay = () => {
  stopAutoplay();
  isPlayed.value = true;
  setTimeout(() => {
    startAutoplay({
      delay: autoplayDelay.value,
      stopOnLastSlide: true,
      disableOnInteraction: false,
    });
  }, activeAutoplay.value);
};

const setStopAutoplay = () => {
  isPlayed.value = false;
  stopAutoplay();
};

const setFinalyAutoplay = () => {
  isPlayed.value = false;
  stopAutoplay();
};

const setAutoplayTimeOut = (time) => {
  autoplayDelay.value = time;

  if (isPlayed.value) {
    setStartAutoplay();
  }
};

const handleChangePrevSlide = () => {
  slidePrev();
};

const handleChangeNextSlide = () => {
  slideNext();
  if (isEnd.value) {
    if (repeatClickEnd.value) {
      closeGallery();
    }
    repeatClickEnd.value = true;
  }
};

const changeSlide = (e) => {
  currentSlide.value = e.realIndex + 1;
  isLockAutoplay.value = e.progress === 1;
};

const toggleFullScreen = () => {
  if (!document.fullscreenElement) {
    document.documentElement.requestFullscreen();
    isFullScreen.value = true;
  } else if (document.exitFullscreen) {
    document.exitFullscreen();
    isFullScreen.value = false;
  }
};

const toggleAutoplayTiming = () => {
  selectedAutoplayTiming.value = !selectedAutoplayTiming.value;
};

onClickOutside(dropdownRef, () => {
  selectedAutoplayTiming.value = false;
});

const closeGallery = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen();
    isFullScreen.value = false;
  }
  modal.closeModal();
};
</script>

<style lang="scss">
.control:not(.control:hover) {
  animation: initialize 3000ms;
}

@keyframes initialize {
  0% {
    opacity: 100;
  }
  70% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}
</style>
